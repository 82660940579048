import axios from 'axios';

import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { FaFileDownload } from "react-icons/fa";
import { MdDownload, MdArrowBackIos } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { BiSolidOffer } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import EmptyProfile from '../../../images/dashboard/empty-profile.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatSession } from '../../../Redux/Slice';

const OrderHistoryPreview = ({ data, show, handleClose }) => {
    const dispatch = useDispatch();
    const { ChatHistory, AdminProfile } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [showChat, setShowChat] = useState(false);
    const [offerApplied, setOfferApplied] = useState(false);
    const [recordingUrl, setRecordingUrl] = useState(null);

    let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

    let StartTime = data?.type === "call" ?
        new Date(data?.calls[0]?.StartTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.type === "chat" ?
            new Date(data?.chatsession?.startTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let EndTime = data?.type === "call" ?
        new Date(data?.calls[0]?.EndTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.type === "chat" ?
            new Date(data?.chatsession?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let Minute = data?.type === "call" ?
        Math.floor(Number(data?.calls[0]?.ConversationDuration) / 60)
        : data?.type === "chat" ?
            Math.floor(data?.chatsession?.duration / 60)
            : 0;

    let Seconds = data?.type === "call" ?
        Number(data?.calls[0]?.ConversationDuration) % 60
        : data?.type === "chat" ?
            Math.floor(data?.chatsession?.duration % 60)
            : 0;

    const viewChatHistory = () => {
        setShowChat(true);
    }

    useEffect(() => {
        if (data?.type === "chat") {
            let messageSessionId = data?.chatsession?.id;
            dispatch(GetChatSession({ token, messageSessionId }));
        }
    }, [dispatch, token, data]);

    const fetchRecordingUrl = async (url) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get-recording-url?url=${url}`, {
                headers: {
                    'authorization': token,
                },
            })
            // .then((response) => {
            //     console.log(response?.data?.data);

            //     let RecordingBlob = new Blob([response?.data?.data], { type: "audio/mpeg" });
            //     console.log(RecordingBlob);

            //     let RecordingObjectUrl = URL.createObjectURL(RecordingBlob);

            //     console.log(RecordingObjectUrl);

            //     setRecordingUrl(RecordingObjectUrl);
            // })

            console.log(response);


            if (response?.data?.data) {
                console.log(response?.data?.data);

                const byteArray = new Uint8Array(response?.data?.data?.data);

                console.log(byteArray);
                
                // Convert the response data into a Blob
                let recordingBlob = new Blob([byteArray], { type: "audio/mpeg" }); // Ensure correct MIME type
                console.log(recordingBlob);

                let recordingObjectUrl = URL.createObjectURL(recordingBlob);
                console.log(recordingObjectUrl);

                // Set the audio URL to state
                setRecordingUrl(recordingObjectUrl);
            }
        } catch (error) {
            console.error('Error fetching audio:', error);
        }
    }

    useEffect(() => {
        if (data?.calls?.length > 0) {
            let url = data?.calls[0]?.RecordingUrl;
            // let url = 'https://recordings.exotel.com/exotelrecordings/astromindcounsel2/c7cdd1c7933f55906584e7c5637418bl.mp3';
            fetchRecordingUrl(url);
        }
    }, [data]);

    console.log(recordingUrl);

    return (
        <>
            <Offcanvas className="call-canvas" show={show} onHide={handleClose} placement={window.screen.width < 600 ? 'bottom' : 'end'} name={window.screen.width < 600 ? 'bottom' : 'end'} backdrop={false} scroll={true} >
                <Offcanvas.Header closeButton className={showChat ? 'chat-history-header' : null}>
                    {showChat ?
                        <Col className='d-flex gap-5'>
                            <div className='back-icon'>
                                <MdArrowBackIos className='icon' onClick={() => setShowChat(false)} />
                            </div>
                            <h4>{data?.order?.subuser?.name}</h4>
                        </Col> : null}
                </Offcanvas.Header>
                <Offcanvas.Body className='chat-history-body'>
                    {showChat ?
                        <Col lg={12} className='mt-5'>
                            <Col lg={12} id="chat-feed" className="d-flex flex-column chat-messages chat-history-message">
                                {
                                    ChatHistory?.data?.data?.map((item, index) => {
                                        const url = item.message;
                                        const imageExtension = url?.split('.').pop().toLowerCase();
                                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(imageExtension);
                                        const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                        const urlFile = isFile ? new URL(url) : null;
                                        const pathname = isFile ? urlFile.pathname : null;

                                        let messageTime = new Date(item?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

                                        return (
                                            <>
                                                <Col lg={12} className={`d-flex ${item.role === 'astrologer' ? 'justify-content-end' : 'justify-content-start'}`} key={index}>

                                                    {isImage ?
                                                        <div className="chat-image" >
                                                            <img src={item.message} alt="" className="img-fluid" />
                                                            <div className="image-download">
                                                                <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                                <span>{messageTime}</span>
                                                            </div>
                                                        </div>
                                                        : isFile ?
                                                            <div className="chat-file">
                                                                <div className="file">
                                                                    <a href={item.message}>
                                                                        <FaFileDownload className="icon" />
                                                                    </a>
                                                                </div>
                                                                <div className="file-name">
                                                                    <p>{pathname?.slice(15)}</p>
                                                                    <span>{messageTime}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="chat-message">
                                                                <p>{item.message}</p>
                                                                <span>{messageTime}</span>
                                                            </div>
                                                    }

                                                </Col>
                                            </>
                                        );
                                    })
                                }

                            </Col>
                        </Col>
                        :
                        <Col className='preview-card'>
                            <Col lg={12} sm={12} className='d-flex justify-content-end mb-3'>
                                {
                                    data?.payment_type == "free" ?
                                        <Col lg={2} sm={2} className="bg-darkBlue rounded text-center">
                                            <p className="text-orange mb-0">FREE</p>
                                        </Col>
                                        : null
                                }
                            </Col>
                            <Col lg={12} sm={12} className='d-flex justify-content-between mb-3'>
                                <Col lg={8} sm={8}>
                                    <p className='preview-data'><span>User Details </span></p>

                                    <p className='preview-data'><span>Name :</span>{data?.user?.name}</p>

                                    <p className='preview-data'><span>AMC ID :</span>{data?.user?.amc_id}</p>
                                </Col>
                                <Col lg={4} sm={4} >
                                    <div className='call-profile-img mt-0'>
                                        <img src={data?.user?.files[0]?.file ? data?.user?.files[0]?.file : EmptyProfile} alt="" className='img-fluid' />
                                    </div>
                                </Col>
                            </Col>
                            <Col lg={12} sm={12} className='d-flex justify-content-between my-4'>
                                <Col lg={8} sm={8}>
                                    <p className='preview-data'><span>Astrologer Details </span></p>

                                    <p className='preview-data'><span>Name :</span>{data?.astrologer?.name}</p>

                                    <p className='preview-data'><span>AMC ID :</span>{data?.astrologer?.amc_id}</p>

                                </Col>
                                <Col lg={4} sm={4} >
                                    <div className='call-profile-img mt-0'>
                                        <img src={data?.astrologer?.files[0]?.file ? data?.astrologer?.files[0]?.file : EmptyProfile} alt="" className='img-fluid' />
                                    </div>
                                </Col>
                            </Col>
                            <Col lg={12} sm={12} className='d-flex flex-wrap justify-content-between my-3'>
                                <Col lg={12} sm={12}>
                                    <p className='preview-data'><span>Order Details </span></p>
                                </Col>
                                <Col lg={6} sm={6}>
                                    <p className='preview-data'><span>Order ID :</span>{data?.id}</p>

                                    <p className='preview-data'><span>Total Rate :</span>₹ {data?.totalrate}</p>

                                    <p className='preview-data'><span>Start Time :</span> {data?.calls?.length > 0 ? StartTime : data?.chatsession?.startTime ? StartTime : "none"}</p>

                                    <p className='preview-data'><span className='status'>Status :</span> {data?.calls?.length > 0 ? data?.calls[0]?.Status : data?.chatsession?.status ? data?.chatsession?.status : data?.status}</p>
                                </Col>
                                <Col lg={6} sm={6}>
                                    <p className='preview-data'><span>Date :</span>{indianTime}</p>

                                    <p className='preview-data'><span>Astrologer Share :</span>₹ {data?.astrologer_share_cost ? data?.astrologer_share_cost : '0'}</p>

                                    <p className='preview-data'><span>End Time :</span> {data?.calls?.length > 0 ? EndTime : data?.chatsession?.endTime ? EndTime : "none"}</p>

                                    <p className='preview-data'><span>Duration :</span> {data?.calls?.length > 0 ? <>{`${Minute} min ${Seconds} sec`}</> : (data?.chatsession?.duration > 0) && data?.chatsession?.status === 'completed' ? <>{`${Minute} min ${Seconds} sec`}</> : "none"}</p>
                                </Col>
                            </Col>
                            {
                                data?.isOfferApplied ?
                                    <>
                                        <div
                                            className={`bg-darkBlue py-2 px-3 offer-badge ${offerApplied ? "radius-top" : "radius-all"}`}
                                            onClick={() => { setOfferApplied(!offerApplied) }}
                                        >
                                            <div className='d-flex align-items-center gap-2'>
                                                <BiSolidOffer className='offer-icon' />
                                                <h5 className='mb-0 text-white'>Offer Applied</h5>
                                            </div>
                                            <div >
                                                {
                                                    offerApplied ?
                                                        <FaChevronUp className='offer-icon' />
                                                        :
                                                        <FaChevronDown className='offer-icon' />
                                                }

                                            </div>
                                        </div>
                                        {
                                            offerApplied ?
                                                <div className='bg-darkBlue p-3 px-5 d-flex justify-content-between radius-bottom'>
                                                    <p className='mb-0 text-white'><span>Offer Name : </span>{data?.offer?.name}</p>
                                                    <p className='mb-0 text-white'><span>Offer Percentage : </span>{data?.offer?.discountpercentage} %</p>
                                                </div>
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    null
                            }
                            {
                                data?.review ?
                                    <>
                                        <div>
                                            <p className='preview-data'><span>User Review</span></p>
                                        </div>
                                        <div className='user-review p-2 px-3'>
                                            <div className='profileStars'>
                                                <div className="profileStar">
                                                    <span>
                                                        {[...new Array(5)].map((arr, index) => {
                                                            return index < `${data?.review?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                        })}
                                                    </span>

                                                </div>
                                            </div>
                                            <div>
                                                <p className='preview-data'>{data?.review?.comment}</p>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                AdminProfile?.data?.data?.user?.roleId === 3 ?
                                    null
                                    :
                                    <>
                                        {data?.calls?.length > 0 ?
                                            <>
                                                <div className='call-audio mt-3'>
                                                    <ReactAudioPlayer src={recordingUrl} controls />
                                                </div>
                                            </>
                                            : null
                                        }
                                        {
                                            data?.type === "chat" && ChatHistory?.data?.data?.length > 0 ?
                                                <div className='view-chat-btn mt-2'>
                                                    <button onClick={() => viewChatHistory()}>View Chat</button>
                                                </div>
                                                : null
                                        }
                                    </>
                            }
                        </Col>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OrderHistoryPreview;